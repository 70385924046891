// Animations (from mdl http://www.getmdl.io/)

@mixin material-animation-fast-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-slow-in;
}

@mixin material-animation-linear-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-linear-out-slow-in;
}

@mixin material-animation-fast-out-linear-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-linear-in;
}

@mixin material-animation-default($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-default;
}

@mixin rotate-180() {
    -webkit-transform: rotate( 180deg );
    -moz-transform: rotate( 180deg );
    -o-transform: rotate( 180deg );
    -ms-transform: rotate(180deg);
    transform: rotate( 180deg );
}

@mixin transform-scale($value){
     -webkit-transform: scale($value);
        -moz-transform: scale($value);
        -o-transform: scale($value);
        -ms-transform: scale($value);
        transform: scale($value);
}

@mixin rotateY-180() {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    -ms-transform: rotateY(180deg);
    transform: rotateY( 180deg );
}

@mixin transitions($time, $type){
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin transitions-property($property, $time, $type){
    -webkit-transition: $property $time $type;
    -moz-transition: $property $time $type;
    -o-transition: $property $time $type;
    -ms-transition: $property $time $type;
    transition: $property $time $type;
}

@mixin transform-translate-x($value){
     -webkit-transform:  translate3d($value, 0, 0);
        -moz-transform: translate3d($value, 0, 0);
        -o-transform: translate3d($value, 0, 0);
        -ms-transform: translate3d($value, 0, 0);
        transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value){
     -webkit-transform:  translate3d(0,$value, 0);
        -moz-transform: translate3d(0, $value, 0);
        -o-transform: translate3d(0, $value, 0);
        -ms-transform: translate3d(0, $value, 0);
        transform: translate3d(0, $value, 0);
}

@mixin perspective($value){
    -webkit-perspective: $value;
    -moz-perspective: $value;
    -o-perspective: $value;
    -ms-perspective: $value;
    perspective: $value;
}

@mixin transform-style($type){
    -webkit-transform-style: $type;
    -moz-transform-style: $type;
    -o-transform-style: $type;
    -ms-transform-style: $type;
    transform-style: $type;
}

@mixin backface-visibility($type){
    -webkit-backface-visibility: $type;
    -moz-backface-visibility: $type;
    -o-backface-visibility: $type;
    -ms-backface-visibility: $type;
        backface-visibility: $type;
}

@mixin transform-translate-y-dropdown($value){
     -webkit-transform:  translate3d(0, $value, 0) !important;
        -moz-transform: translate3d(0, $value, 0) !important;
        -o-transform: translate3d(0, $value, 0) !important;
        -ms-transform: translate3d(0, $value, 0) !important;
        transform: translate3d(0, $value, 0) !important;
}
