.img-thumbnail{
    border-radius: 16px;
}
.img-raised{
    @include shadow-big-image();
}

.rounded{
    border-radius: $border-radius-large !important;
}
