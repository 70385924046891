// Navs https://www.google.com/design/spec/components/tabs.html#tabs-specs

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

$bmd-navbar-link-font-weight: $bmd-font-weight-base !default; //
$bmd-navbar-link-font-size: .875rem !default; // 14
$bmd-navbar-link-padding: .5321rem; // 7

// tabs & pills
$bmd-nav-tabs-pills-font-weight: 500 !default; //
$bmd-nav-tabs-pills-font-size: .875rem !default; // 14
$bmd-nav-tabs-pills-link-padding: 1.4286em .8575em !default; // spec // was .5em 1em   // relative em based on 14

// tabs only
$bmd-nav-tabs-border-size: .214rem !default; // 3px

$bmd-nav-tabs-color: $gray !default;
$bmd-nav-tabs-active-color: $gray-dark !default;
$bmd-nav-tabs-active-border-color: $brand-primary !default;
$bmd-nav-tabs-disabled-link-color: $nav-disabled-link-color !default;
$bmd-nav-tabs-disabled-link-color-hover: $nav-disabled-link-hover-color !default;

$bmd-nav-tabs-primary-color: $bmd-inverse !default;
$bmd-nav-tabs-primary-active-color: #fff !default;
$bmd-nav-tabs-primary-active-border-color: #fff !default;
$bmd-nav-tabs-primary-disabled-link-color: $bmd-inverse-light !default;
$bmd-nav-tabs-primary-disabled-link-color-hover: $bmd-inverse-light !default;

$bmd-nav-tabs-inverse-color: $bmd-inverse !default;
$bmd-nav-tabs-inverse-active-color: #fff !default;
$bmd-nav-tabs-inverse-active-border-color: #fff !default;
$bmd-nav-tabs-inverse-disabled-link-color: $bmd-inverse-light !default;
$bmd-nav-tabs-inverse-disabled-link-color-hover: $bmd-inverse-light !default;

//$nav-item-margin:                           .2rem !default;

//$bmd-nav-link-line-height:                  1 !default; // makes it easier to line up with the spec
//$nav-link-padding:                          .5em 1em !default; // changing this for tabs alters generic navbars, so do it elsewhere with higher specificity
//$nav-link-hover-bg:                         $gray-lighter !default;

//
//$nav-tabs-border-color:                     #ddd !default;
//
//$nav-tabs-link-border-width:                $border-width !default;
//$nav-tabs-link-hover-border-color:          $gray-lighter !default;
//
$nav-tabs-active-link-hover-bg: transparent !default; // $body-bg
//$nav-tabs-active-link-hover-color:          $gray !default;
//$nav-tabs-active-link-hover-border-color:   #ddd !default;
//
//$nav-tabs-justified-link-border-color:            #ddd !default;
//$nav-tabs-justified-active-link-border-color:     $body-bg !default;
//
//$nav-pills-border-radius:                   $border-radius !default;
//$nav-pills-active-link-hover-bg:            $component-active-bg !default;
//$nav-pills-active-link-hover-color:         $component-active-color !default;
