@mixin main-panel-background-color($background-color, $font-color){
    background-color: $background-color;
    color: $font-color;
    .navbar{       
        color: $font-color;
        background-color: $background-color !important;        
    }
    .card{
        background-color: $gray-darker;     
        color: $font-color !important;

        .card-title{
            color: $font-color !important;

        }
    }

    .simple-text{
        color: $font-color;
    }
    .main-panel-background:after{
        background: $background-color;
        opacity: .8;
    }
}
