.popover, .tooltip-inner {
    line-height: 1.5em;
    background: $white-color;
    border: none;
    border-radius: $border-radius-base;
    @include shadow-8dp();
    color: $popover-color;
}


.popover{
    padding: 0;
    @include shadow-16dp();

    &.left,
    &.right,
    &.top,
    &.bottom{
        > .arrow{
            border: none;
        }
    }


    &.bs-popover-top,
    &.bs-popover-auto[x-placement^="top"],
    &.bs-popover-bottom,
    &.bs-popover-auto[x-placement^="bottom"],
    &.bs-popover-right,
    &.bs-popover-auto[x-placement^="right"],
    &.bs-popover-left,
    &.bs-popover-auto[x-placement^="left"]{
        & .arrow::before{
            border: 0;
        }
    }

}

.popover-header{
    background-color: $white-color;
    border: none;
    padding: 15px 15px 5px;
    font-size: $font-size-h4;
    margin: 0;
    color: $popover-color;
}

.popover-body{
    padding: 10px 15px 15px;
    line-height: 1.4;
    color: $popover-color;
}
